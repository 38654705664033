<template>
<v-container fluid>
    <v-row>
        <v-col>
            <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" flat>
                <v-card-title :class="$store.getters.getColorPalette().esg_module_card">
                    Insights (Average)
                </v-card-title>
                <v-row>
                    <v-col cols="12">
                        <v-simple-table height="200px" :style="{background: 'transparent'}" >
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            <strong>Remark</strong>
                                        </th>
                                        <th class="text-left">
                                            <strong>Particle (PM2.5)</strong>
                                        </th>
                                        <th class="text-left">
                                            <strong>Particle (PM10)</strong>
                                        </th>
                                        <th class="text-left">
                                            <strong>Carbon Dioxide</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Good
                                        </td>
                                        <td>
                                            10 Days
                                        </td>
                                        <td>
                                            17 Days
                                        </td>
                                        <td>
                                            34 Days
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Fair
                                        </td>
                                        <td>
                                            23 Days
                                        </td>
                                        <td>
                                            56 Days
                                        </td>
                                        <td>
                                            28 Days
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Moderate
                                        </td>
                                        <td>
                                            39 Days
                                        </td>
                                        <td>
                                            44 Days
                                        </td>
                                        <td>
                                            76 Days
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Poor
                                        </td>
                                        <td>
                                            50 Days
                                        </td>
                                        <td>
                                            41 Days
                                        </td>
                                        <td>
                                            22 Days
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Very Poor
                                        </td>
                                        <td>
                                            30 Days
                                        </td>
                                        <td>
                                            66 Days
                                        </td>
                                        <td>
                                            22 Days
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="4">
            <v-card flat>
                <div ref="humiditygaugevis"></div>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card flat>
                <div ref="tempraturegaugevis"></div>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card flat>
                <div ref="co2gaugevis"></div>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="6">
            <v-card flat>
                <div ref="airpollutant10gaugevis"></div>
            </v-card>
        </v-col>
        <v-col cols="6">
            <v-card flat>
                <div ref="airpollutant2gaugevis"></div>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="6">
            <v-card flat>
                <div ref="airqualityvis"></div>
            </v-card>
        </v-col>
        <v-col cols="6">
            <v-card flat>
                <div ref="carbondioxidevis"></div>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="6">
            <v-card flat>
                <div ref="pm2vis"></div>
            </v-card>
        </v-col>
        <v-col cols="6">
            <v-card flat>
                <div ref="pm10vis"></div>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="6">
            <v-card flat>
                <div ref="humidityvis"></div>
            </v-card>
        </v-col>
        <v-col cols="6">
            <v-card flat>
                <div ref="tempraturevis"></div>
            </v-card>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card flat>
                <div ref="vocvis"></div>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
import Plotly from 'plotly.js'
export default {
    name:'Sustainability',
    data(){
        return {
            layout : {
                colorway:this.$store.getters.getColorPalette().colorTypesCodeArray,
                    font:{
                          color:this.$store.getters.getColorPalette().accentCode,
                        },
                    plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    showlegend:false,
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
                    }
        }
    },
    mounted(){
        this.CreateCarbonDioxideChart()
        this.CreatePM2Chart()
        this.CreatePM10Chart()
        this.CreateVOCChart()
        this.CreateHumidityChart()
        this.CreateTempratureChart()
        this.CreateAirQualityChart()
        this.CreateHumidityGauge()
        this.CreateTempratureGauge()
        this.CreateAirPollutant2Gauge()
        this.CreateAirPollutant10Gauge()
        this.CreateCO2Gauge()
    },
    methods:{
        CreateCarbonDioxideChart(){
            this.layout['title']= 'Carbon Dioxide (CO2) Levels'
            this.layout['yaxis']['title']='µg/m3'
            var data = [
                {
                    x: ['13-06-2023','14-06-2023','15-06-2023','16-06-2023','17-06-2023','18-06-2023','19-06-2023'],
                    y: [352, 397, 345,674,239,621,792],
                    type: 'line'
                }
                ];

            Plotly.newPlot(this.$refs.carbondioxidevis, data, this.layout, {displaylogo: false});

        },
        CreatePM2Chart(){
            this.layout['title']= 'Particulate Matter (PM2.5)'
            this.layout['yaxis']['title']='µg/m3'
            var data = [
                {
                    x: ['13-06-2023','14-06-2023','15-06-2023','16-06-2023','17-06-2023','18-06-2023','19-06-2023'],
                    y: [21, 28, 20,29,23,22,29],
                    type: 'bar'
                }
                ];

            Plotly.newPlot(this.$refs.pm2vis, data, this.layout, {displaylogo: false});
        },
        CreatePM10Chart(){
            this.layout['title']= 'Particulate Matter (PM10)'
            this.layout['yaxis']['title']='µg/m3'
            var data = [
                {
                    x: ['13-06-2023','14-06-2023','15-06-2023','16-06-2023','17-06-2023','18-06-2023','19-06-2023'],
                    y: [55, 59, 67,46,47,51,48],
                    type: 'bar'
                }
                ];

            Plotly.newPlot(this.$refs.pm10vis, data, this.layout, {displaylogo: false});
        },
        CreateVOCChart(){
            this.layout['title']= 'Volatile Organic Compounds (VOCs)'
            this.layout['yaxis']['title']='µg/m3'
            var data = [
                {
                    x: ['13-06-2023','14-06-2023','15-06-2023','16-06-2023','17-06-2023','18-06-2023','19-06-2023'],
                    y: [352, 397, 345,674,239,621,792],
                    type: 'line'
                }
                ];

            Plotly.newPlot(this.$refs.vocvis, data, this.layout, {displaylogo: false});
        },
        CreateHumidityChart(){
            this.layout['title']= 'Relative Humidity'
            this.layout['yaxis']['title']='%'
            var data = [
                {
                    x: ['13-06-2023','14-06-2023','15-06-2023','16-06-2023','17-06-2023','18-06-2023','19-06-2023'],
                    y: [78, 72, 76,60,69,78,85],
                    type: 'bar'
                }
                ];

            Plotly.newPlot(this.$refs.humidityvis, data, this.layout, {displaylogo: false});
        },
        CreateTempratureChart(){
            this.layout['title']= 'Temperature'
            this.layout['yaxis']['title']='C°'
            var data = [
                {
                    x: ['13-06-2023','14-06-2023','15-06-2023','16-06-2023','17-06-2023','18-06-2023','19-06-2023'],
                    y: [12, 29, 14,19,14,30,17],
                    type: 'bar'
                }
                ];

            Plotly.newPlot(this.$refs.tempraturevis, data, this.layout, {displaylogo: false});
        },
        CreateAirQualityChart(){
            this.layout['title']= 'Air Quality Index'
            this.layout['yaxis']['title']='AQI'
            var data = [
                {
                    x: ['13-06-2023','14-06-2023','15-06-2023','16-06-2023','17-06-2023','18-06-2023','19-06-2023'],
                    y: [1, 5, 3,3,5,5,6],
                    type: 'line'
                }
                ];

            Plotly.newPlot(this.$refs.airqualityvis, data, this.layout, {displaylogo: false});
        },
        CreateHumidityGauge(){
            this.layout['title']= 'Humidity'
            var data = [
            {
                domain: { x: [0, 1], y: [0, 1] },
                value: 63,
                title: { text: "%" },
                type: "indicator",
                mode: "gauge+number",
                delta: { reference: 400 },
                gauge: { axis: { range: [0, 100] } ,
                bar:{
                  color:this.$store.getters.getColorPalette().bulletGaugeBar,
                },}
            }
            ];
            Plotly.newPlot(this.$refs.humiditygaugevis, data, this.layout, {displaylogo: false});
        },
        CreateTempratureGauge(){
            this.layout['title']= 'Temperature'
            var data = [
            {
                domain: { x: [0, 1], y: [0, 1] },
                value: 23,
                title: { text: "C°" },
                type: "indicator",
                mode: "gauge+number",
                delta: { reference: 400 },
                gauge: { axis: { range: [-20, 40] },
                bar:{
                  color:this.$store.getters.getColorPalette().bulletGaugeBar,
                }, }
            }
            ];
            Plotly.newPlot(this.$refs.tempraturegaugevis, data, this.layout, {displaylogo: false});
        },
        CreateAirPollutant2Gauge(){
            this.layout['title']= 'Air Pollutant PM 2.5'
            var data = [
            {
                domain: { x: [0, 1], y: [0, 1] },
                value: 27,
                title: { text: "µg/m3" },
                type: "indicator",
                mode: "gauge+number",
                delta: { reference: 400 },
                gauge: { axis: { range: [0, 100] },
                bar:{
                  color:this.$store.getters.getColorPalette().bulletGaugeBar,
                }, }
            }
            ];
            Plotly.newPlot(this.$refs.airpollutant2gaugevis, data, this.layout, {displaylogo: false});
        },
        CreateAirPollutant10Gauge(){
            this.layout['title']= 'Air Pollutant PM 10'
            var data = [
            {
                domain: { x: [0, 1], y: [0, 1] },
                value: 61,
                title: { text: "µg/m3" },
                type: "indicator",
                mode: "gauge+number",
                delta: { reference: 400 },
                gauge: { axis: { range: [0, 100] },
                bar:{
                  color:this.$store.getters.getColorPalette().bulletGaugeBar,
                },
             }
            }
            ];
            Plotly.newPlot(this.$refs.airpollutant10gaugevis, data, this.layout, {displaylogo: false});
        },
        CreateCO2Gauge(){
            this.layout['title']= 'Carbon Dioxide'
            var data = [
            {
                domain: { x: [0, 1], y: [0, 1] },
                value: 706,
                title: { text: "µg/m3" },
                type: "indicator",
                mode: "gauge+number",
                delta: { reference: 400 },
                gauge: { axis: { range: [0, 200] } ,                
                bar:{
                  color:this.$store.getters.getColorPalette().bulletGaugeBar,
                },}
            }
            ];
            Plotly.newPlot(this.$refs.co2gaugevis, data, this.layout, {displaylogo: false});
        },
    }
}
</script>